.section {
  width: 100vw;
  min-height: 100vh;
}

.sectionWithTitle {
  width: 100vw;
  min-height: calc(100vh - var(--section-title-height));
}

.sectionFlex {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sectionTitle {
  top: var(--nav-height);
  z-index: 9;
  height: var(--section-title-height);
  width: 100%;
  font-size: 2rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
