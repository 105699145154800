
.card {
  width: 18rem;
  max-width: 95%;
  height: 24rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  font-family: poppins;
  text-align: center;
  background: linear-gradient(135deg, #02010e 0%, #232630 100%);
  border-radius: 10px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s;
  cursor: pointer;
  font-size: 1.1em;
}

*.card:hover {
  transform: scale(1.05);
}

.title, .subtitle {
  width: 100%;
  width: 15rem;
  color: white;
}

.title {
  font-weight: bold;
  font-size: 1.2rem;
}

.title::after {
  content: '';
  height: 2px;
  width: 80%;
  display: block;
  border-radius: 2px;
  margin-left: 50%;
  background: white;
  transform: translateX(-50%);
  margin-top: 2%;
}

.img {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 10px;
  -webkit-user-drag: none;
  transition: transform 0.2s;
}

.img img {
  -webkit-user-drag: none;
  user-select: none;

  max-height: 100%;
  max-width: 100%;
  border-radius: 10px;
}
