.card {
  height: 13rem;
  width: 13rem;
  max-width: 95%;
  border-radius: 1rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #232627;
}

.card:hover .textWrapper {
  opacity: 100%;
  transition: opacity 0.4s;
}

.img {
  height: 80%;
  width: 80%;
  object-fit: contain;
  display: flex;
  justify-content: center;
  align-items: center;
}

.textWrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(35, 38, 39, 0.3);
  backdrop-filter: blur(2px);
  border-radius: 1rem;
  opacity: 0%;
  transition: opacity 0.2s;
}

.title {
  font-size: 1.7rem;
  font-weight: bold;
}

.title, .subtitle {
  max-width: 80%;
  text-align: center;
}

.img img {
  max-width: 100%;
  max-height: 100%;
}
