:root {
  --nav-height: 5rem;
  --section-title-height: 4rem;
}

body{
  margin: 0;
  padding: 0;
  background-color: black;
  overflow-x: hidden;
}

main {
  padding: 0;
  margin: 0;
}

@media (max-width: 1281px) {
  main {
    padding-top: calc(var(--nav-height) + 2rem);
  }
}
