.card {
    margin-top: 4rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 280px;
    max-width: 100%;
    height: 300px;
    background: linear-gradient(135deg, #02010e 0%, #232630 100%);
    border-radius: 20px;
    transition: 0.7s;
    box-shadow: 0 35px 80px rgba(0, 0, 0, 0.15);
}

.card:hover {
    height: 335px;
    width: 350px;
}

.card .img{
    position: absolute;
    top: 30px;
    width: 250px;
    height: 200px;
    border-radius: 12px;
    overflow: hidden;
    transition: 0.5s;
}

.card:hover .img {
    top: -70px;
    scale: 0.80;
    box-shadow: 0 15px 45px rgba(0, 0, 0, 0.2);
    height: 200px;
    width: 300px;
}

.card .img img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.card .textWrapper {
    position: absolute;
    top: 252px;
    width: 50%;
    height: 35px;
    padding: 0 30px;
    text-align: center;
    overflow: hidden;
    transition: 0.5s;
}

.card:hover .textWrapper {
    top: 130px;
    height: 250px;
}

.card .textWrapper h2{
    font-size: 1rem;
    font-weight: 700;
    color: ghostwhite;
}

.card .textWrapper p {
    color: seashell;
}