.menu {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
  height: 100vh;
  width: 100vw;
  z-index: 11;
  position: fixed;
  top: 0; left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.7rem;
}

.menu nav a {
  color: ghostwhite;
  text-decoration: none;
  font-size: 1.4rem;
  font-weight: bold;
}

.menu nav .current {
  text-decoration: underline;
}

.menu .closeButton {
  position: fixed;
  top: 2.5rem;
  right: 2.8rem;
  color: white;
  font-size: 2rem;
  font-family: sans-serif;
  cursor: pointer;
}
