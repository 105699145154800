.navbar {
  height: var(--nav-height);
  width: 100%;
  position: fixed;
  top: 0; left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.4rem;
  background-color: #272727;
  z-index: 10;
}

.image-container {
  height: calc(var(--nav-height) - 0.7rem);
  width: calc(var(--nav-height) - 0.7rem);
  margin-left: 5rem;
}

.image-container img {
  max-height: 100%;
  max-width: 100%;
}

.navbar nav {
  display: flex;
  gap: 3rem;
  margin-right: 2.5rem;
}

.navbar nav a {
  color: ghostwhite;
  text-decoration: none;
}

.navbar nav a.current {
  text-decoration: underline;
}

.verticalDots {
  width: 0.5rem;
  height: 2rem;
  background-image: radial-gradient(circle, ghostwhite 0.2rem, transparent 0.2rem);
  background-size: 100% 33.33%;
  transform: rotate(90deg);
  display: none;
  cursor: pointer;
}

@media (max-width: 641px)  {
  .navbar a {
    display: none;
  }
}

@media (max-width: 1281px) {
  .navbar .lowPriority {
    display: none;
  }

  .navbar .verticalDots {
    display: block;
  }
}
